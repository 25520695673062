import React from "react";
// Homepage component
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="jumbotron">
              <h1 className="display-4"><span><i>Welcome to the LMA Portal</i></span></h1>
              <h5 className="display-4">LMA: Logging, Monitoring and Alerting</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
