import React from "react";
import { API } from "aws-amplify";
import data from "./test_data/data"; // used for dev testing
import { trackPromise } from 'react-promise-tracker'; // used for loading screen
import SearchAgentsTable from "./SearchAgentsTable";
import { displayAlert } from "../Helper/Functions";
const how_to_use = "how_to_use";



class CrowdstrikeAgents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agents_to_search: null, // user's input of agents to search
      results: [], // results returned from api
      gotAgents: false,
      showAgents: false,
      agentsError: false,
      test_data: data, // used for local test data
    };
    this.initialSetState = this.initialSetState.bind(this); // allows the function 'initialSetState' to modify the 'this.state.<properties>' (e.g. 'results', 'gotAgents', etc)
    this.getAgents = this.getAgents.bind(this); // allows the function 'initialSetState' to modify the 'this.state.<properties>' (e.g. 'results', 'gotAgents', etc)
    this.displayResults = this.displayResults.bind(this);
  }
  
  componentDidMount() {}

  initialSetState() {
    this.setState({
      results: [],
      gotAgents: false,
      showAgents: false,
      agentsError: false
    });
  }

  // Handles changes to fields where users input agent search
  handleInputChange(event) {
    console.log(event)
    let change = {}
    change[event.target.name] = event.target.value // takes the changed event's targe name & value
    this.setState(change) // changes the field value in constructor for `this.state`
    console.log(event.target.name) // logs the event target name that changed (e.g. "agents_to_search")
    console.log(event.target.value) // logs the event target value that changed (e.g. "dev-wazuh-manager01...")
    console.log(change) // logs how the final key/value would look in constructor for `this.state` after reflecting the changes
  }

/*
  // Displays table with results
  displayResults(results) {
    let resultsLength = results.length;
    if (resultsLength>0) {
      return (
        <div>
          <SearchAgentsTable results={results} />
        </div>
      );
    }
    else {
      return null
    }
  }
  */
  displayResults(results) {
      return (
        <div>
          {results}
        </div>
      );
  }

  getAgents() {
    // Get the values of agents to search from this.state.gents_to_search
    let agent_search_string = JSON.parse(JSON.stringify(this.state.agents_to_search));
    console.log('Your search string is => "' + agent_search_string + '"');
    // Check if search input is empty
    if (agent_search_string.match(/^ *$/)) {
      console.log("Error: User had an empty agent search string")
      return displayAlert('error', 'Agent search cannot be empty')
    }
    // Check if search input is valid - must contain at least a letter or number. Zero or more '_', '-', '.')
    else  {
      this.setState({
      gotAgents: false,
      agentsError: false,
      results: [] // Empty out the results if any
      });

      // Combine api and agent search string to create final search string for API
      let finalSearchString = agent_search_string;
      console.log('Lambda final query string => ' + finalSearchString);

      trackPromise(
        // Call API and send search string
        API.get("lma-portal", "/lma-portal-prod-getinfo", {withCredentials: false})
        .then(response => {
          console.log(response);
          let response_data = response;
          // Check if data was not returned
          if (response_data === "No data was found"){
            // Warning alert bc no data was successfully returned
            displayAlert('warning', 'Your search yielded zero results.');

            this.setState({
              gotAgents: false,
              agentsError: true
            });
          }
          // Data was returned
          else {
            // Success alert
            displayAlert('success', 'Your search returned results!');
            // what does promise do???
            Promise.all([response_data]).then(() => {
              this.setState({
                results: response_data,
                gotAgents: true,
                agentsError: false
              });
            });
          }
        })
        .catch(error => {
          // Display error alert
          displayAlert('error', 'An error occured => "' + error + '"')
        })
      );
    }
  }

  render() {

    const { results } = this.state // Sets vars based on `this.state` from constructor

    return (
      <div className="main-container height-100">
        <div>
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="mb-3">
                <div>

                  <div className="row w-100 d-flex justify-content-between align-items-center m-0">
                    {/* Below displays user helpful buttons */}
                    <div className="text-center mb-3">
                      <button className="btn btn-info m-1" onClick={() => displayAlert('info', how_to_use)}>How do I use this search portal?</button>
                      {/* <button className="btn btn-warning m-1" onClick={() => displayAlert('info', found_possible_bug)}>Found a possible bug?</button> */}
                    </div>
                  </div>

                  <div className="row w-100 d-flex justify-content-between align-items-center m-0">
                    <h5 className="text-info ml-3 mb-1">Enter Asset Details</h5>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input type="search" name="agents_to_search" autoComplete="off" placeholder="Example: 143.8.86.168, 5CG92064PB-W10" className="form-control" onChange={this.handleInputChange.bind(this)} required title="Enter Asset details"/>
                        </div>
                      </div>
                      <div className="mt-3 mb-3">
                        <div className="text-right mb-3">
                          <button type="button" disabled={!this.state.agents_to_search} onClick={this.getAgents} className="btn btn-primary btn-lg m-1" title="Searches your agents">Search</button>
                          {/* <button type="button" name="agents_to_search" onClick={this.handleInputChange.bind(this)} className="btn btn-warning btn-lg m-1" title="Resets your search criteria">Reset</button> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.displayResults(results)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CrowdstrikeAgents;
