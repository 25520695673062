// Setup config for dev and prod, env var automatically switches:
// npm start == development   npm run-script build == production
let config = {};
 {
  // Prod
  config = {
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://api.lma-portal.pgcloud.com"
    },
    pingfed: {
      client_id: "Humio-AWS-Prod-Instance",
      redirect_uri: "https://lma-portal.pgcloud.com/authentication/callback",
      response_type: "code",
      post_logout_redirect_uri: "https://lma-portal.pgcloud.com/",
      scope: "openid profile email pingid",
      authority: "https://fedauth.pg.com",
      silent_redirect_uri:
        "https://lma-portal.pgcloud.com/authentication/silent_callback",
      automaticSilentRenew: true,
      loadUserInfo: false,
      triggerAuthFlow: true,
      extraQueryParams: { pfidpadapterid: "ExcludeEBPAdapters" }
    }
  };
} 

export default config;
