// Definition for columns shown in an agent search
export let WazuhClusters = [
  // value is literal value of the item, LOWERCASE
  // label is what is displayed in the dropdown selector
  { "value": "dev", "label": "Dev Environment" },
  // { "value": "aws-prod", "label": "AWS Production" },
  // { "value": "aws-nonprod", "label": "AWS Nonprod" },
  // { "value": "azure-prod", "label": "Azure Production" },
  // { "value": "azure-nonprod", "label": "Azure Nonprod" },
  // { "value": "noncloud", "label": "Noncloud" }
];

// Definition for columns shown in an agent search
export let AgentColumns = [
  // dataField is the agent attribute, LOWERCASE
  // text is what is displayed at the top of a table to describe the column
  // sort determines if that column can be sorted or not in the results tables
  { dataField: "id", text: "ID", sort: true },
  { dataField: "name", text: "Name", sort: true },
  { dataField: "status", text: "Status", sort: true },
  { dataField: "version", text: "Version", sort: true },
  { dataField: "last_event", text: "Last Event", sort: true },
  { dataField: "key_status", text: "Key Status", sort: true }
];