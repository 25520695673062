import config from "./config";
import React from "react";
import Main from "./Components/Main";
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import {
  AuthenticationProvider,
  getUserManager,
  oidcLog,
  OidcSecure,
} from "@procter-gamble/pg-react-auth";
import Amplify from "aws-amplify";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "lma-portal",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          var authorization =
            process.env.REACT_APP_STAGE !== "production" &&
            process.env.REACT_APP_STAGE !== "non-prod"
              ? (await getUserManager().getUser()).access_token
              : "undefined";
          return {
            Authorization: authorization,
          };
        }, 
      },
    ],
  },
});

const Wrapper = () => (
  <Router>
    { <Main />}
  </Router>
);

render(<Wrapper />, document.getElementById("root"));


//backup old code