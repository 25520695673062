import React from "react";
import Select from 'react-select';
import PropTypes from "prop-types";
import { WazuhClusters } from "../FieldDefinitions/Agents/SearchAgents";

class SearchAgentsClusterDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var name = this.props.name;
    var onChange = this.props.onChange;
    var options = WazuhClusters;
    var placeholder = this.props.placeholder;
    return (
        <div className="col-md-12">
          <Select
            className="basic-single"
            classNamePrefix="select"
            isSearchable={false}
            // defaultValue={WazuhClusters[0]}
            onChange={onChange}
            name={name}
            options={options}
            placeholder={placeholder}
          />
        </div>
    );
  }
}


SearchAgentsClusterDropdown.defaultProps = {
  name: "wazuh-cluster",
  onChange: function () {
      console.error("onChange function not supplied");
    },
  placeholder: "Items...",
};

SearchAgentsClusterDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};


export default SearchAgentsClusterDropdown;