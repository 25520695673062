import React from "react";
import { API } from "aws-amplify";
import data from "./test_data/data"; // used for dev testing
import { trackPromise } from 'react-promise-tracker'; // used for loading screen
import SearchAgentsTable from "./SearchAgentsTable";
import SearchAgentsClusterDropdown from "./SearchAgentsClusterDropdown";
import { displayAlert } from "../Helper/Functions";
const how_to_use = "how_to_use";
const found_possible_bug = 'found_possible_bug';

class Agents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCluster: null, // user's selected cluster
      agents_to_search: null, // user's input of agents to search
      results: [], // results returned from api
      gotAgents: false,
      showAgents: false,
      agentsError: false,
      test_data: data, // used for local test data
    };
    this.initialSetState = this.initialSetState.bind(this); // allows the function 'initialSetState' to modify the 'this.state.<properties>' (e.g. 'results', 'gotAgents', etc)
    this.getAgents = this.getAgents.bind(this); // allows the function 'initialSetState' to modify the 'this.state.<properties>' (e.g. 'results', 'gotAgents', etc)
    this.displayDropdown = this.displayDropdown.bind(this);
    this.displayResults = this.displayResults.bind(this);
  }

  componentDidMount() {}

  initialSetState() {
    this.setState({
      results: [],
      gotAgents: false,
      showAgents: false,
      agentsError: false
    });
  }

  // Handles changes to the dropdown select for Wazuh cluster
  handleClusterSelection = selectedOption => {
    console.log("Previous set cluster:", this.state.selectedCluster)
    this.setState(
      // Sets this.state.selectedCluster (e.g. {'value': 'aws-prod', 'label': 'AWS Prod'})
      { selectedCluster: selectedOption },
      () => console.log('Value of option selected:', this.state.selectedCluster['value'])
    );
  };

  // Handles changes to fields where users input agent search
  handleInputChange(event) {
    console.log(event)
    let change = {}
    change[event.target.name] = event.target.value // takes the changed event's targe name & value
    this.setState(change) // changes the field value in constructor for `this.state`
    console.log(event.target.name) // logs the event target name that changed (e.g. "agents_to_search")
    console.log(event.target.value) // logs the event target value that changed (e.g. "dev-wazuh-manager01...")
    console.log(change) // logs how the final key/value would look in constructor for `this.state` after reflecting the changes
  }

  // Displays dropdown menu
  displayDropdown() {
    return (
      <div>
        <SearchAgentsClusterDropdown name={"selected-wazuh-cluster"} onChange={this.handleClusterSelection} placeholder={"Clusters..."} />
      </div>
    );
  }

  // Displays table with results
  displayResults(results) {
    let resultsLength = results.length;
    if (resultsLength>0) {
      return (
        <div>
          <SearchAgentsTable results={results} />
        </div>
      );
    }
    else {
      return null
    }
  }


  // Below was replaced by the new way we are doing dropdown for selecting wazuh cluster
  // Displays options for wazuh cluster dropdown
  // this.state.available_apis: [
  //   { cluster: "AWS-Prod"},
  //   { cluster: "AWS-Nonprod"},
  //   { cluster: "Azure-Prod"},
  //   { cluster: "Azure-Nonprod"},
  //   { cluster: "Noncloud"}
  // ]
  // renderApiTableData() {
  //   return this.state.available_apis.map((apis) => {
  //     const { cluster, field1, field2, field3 } = apis //destructuring
  //     return (
  //       <option value={cluster}>{cluster}</option>
  //     )
  //   })
  // }


  // Below was the old way to generate a table with the results. This was replaced by `pg-react-styledtable`
  // renderTable() {
  //   let resultsLength = this.state.results.length
  //   if (resultsLength>0) {
  //     return (
  //       <div className="react-bootstrap-table">
  //         <table className="table table-hover table-bordered" id='agents'>
  //           <thead>
  //             <tr>
  //               {this.state.test_columns.map((column) => (
  //                   <th tabIndex="0" aria-label={column + " sort asc"} className="sortable">
  //                     {column}
  //                     <i className="fa fa-fw text-dark text-right fa-caret-down">
  //                     </i>
  //                   </th>
  //               ))}
  //             </tr>
  //           </thead>
  //           <tbody>
  //             {this.state.results.map((agent,index) => (
  //               <tr key={index}>
  //                 <td>{agent.id}</td>
  //                 <td>{agent.name}</td>
  //                 <td>{agent.status}</td>
  //                 <td>{agent.version}</td>
  //                 <td>{agent.last_event}</td>
  //               </tr>
  //             ))}
  //           </tbody>
  //         </table>
  //       </div>

  //       )
  //     } else {
  //       return null
  //     }
  // }


  getAgents() {
    // Check this.state.selectedCluster is not empty (basically, that user has selected a clsuter)
    let clusterSelected = this.state.selectedCluster;
    if (!clusterSelected) {
      console.log("Error: User did not select a cluster")
      return displayAlert('error', 'Cluster selection cannot be empty')
    }

    // Get the values of agents to search from this.state.gents_to_search
    let agent_search_string = JSON.parse(JSON.stringify(this.state.agents_to_search));
    console.log('Your search string is => "' + agent_search_string + '"');
    // Check if search input is empty
    if (agent_search_string.match(/^ *$/)) {
      console.log("Error: User had an empty agent search string")
      return displayAlert('error', 'Agent search cannot be empty')
    }
    // Check if search input is valid - must contain at least a letter or number. Zero or more '_', '-', '.')
    else if (agent_search_string.match(/^(?=.*[a-zA-Z0-9]_*-*).*$/)) {
      this.setState({
      gotAgents: false,
      agentsError: false,
      results: [] // Empty out the results if any
      });

      // Create cluster search string for API
      let clusterSearchString = "?cluster=" + this.state.selectedCluster['value'];
      console.log('Lambda cluster searchstring => ' + clusterSearchString);
      // Create agent search string for API
      let agentSearchString = "agent_search=" + this.state.agents_to_search;
      console.log('Lambda agent searchstring => ' + agentSearchString);
      // Combine api and agent search string to create final search string for API
      let finalSearchString = clusterSearchString + "&" + agentSearchString;
      console.log('Lambda final query string => ' + finalSearchString);

      trackPromise(
        // Call API and send search string
        API.get("wazuh-portal", "/agents" + finalSearchString, {  //using stack/service/getAgents/getAgents.py because that script maps to this path in the stack/service/getAgents/serverless.yml functions block
          withCredentials: true
        })
        .then(response => {  // handling response https://stackoverflow.com/questions/49725012/handling-response-status-using-fetch-in-react-js/49725163
          let response_data = response;
          // Check if data was not returned
          if (response_data === "No data was found"){
            // Warning alert bc no data was successfully returned
            displayAlert('warning', 'Your search yielded zero results.');

            this.setState({
              gotAgents: false,
              agentsError: true
            });
          }
          // Data was returned
          else {
            // Success alert
            displayAlert('success', 'Your search returned results!');
            // what does promise do???
            Promise.all([response_data]).then(() => {
              this.setState({
                results: response_data,
                gotAgents: true,
                agentsError: false
              });
            });
          }
        })
        .catch(error => {
          // Display error alert
          displayAlert('error', 'An error occured => "' + error + '"')
        })
      );
    }
    // Search input is invalid
    else {
      {displayAlert('error', 'Searches can only contain letters, numbers, periods, hyphens, or underscores')};
    }
  }

  render() {

    const { results } = this.state // Sets vars based on `this.state` from constructor

    return (
      <div className="main-container height-100">
        <div>
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="mb-3">
                <div>

                  <div className="row w-100 d-flex justify-content-between align-items-center m-0">
                    <div className="form-group">
                      <h5 className="text-info ml-3 mb-1">Selected Wazuh Cluster</h5>
                      {/* Below displays the dropdown for the Wazuh cluster selection */}
                      {this.displayDropdown()}
                    </div>
                    {/* Below displays user helpful buttons */}
                    <div className="text-center mb-3">
                      <button className="btn btn-info m-1" onClick={() => displayAlert('info', how_to_use)}>How do I use this search portal?</button>
                      {/* <button className="btn btn-warning m-1" onClick={() => displayAlert('info', found_possible_bug)}>Found a possible bug?</button> */}
                    </div>
                  </div>

                  <div className="row w-100 d-flex justify-content-between align-items-center m-0">
                    <h5 className="text-info ml-3 mb-1">Enter Agent IDs / Names</h5>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input type="search" name="agents_to_search" autoComplete="off" placeholder="Example: 001, gadc-sqlserver01" className="form-control" onChange={this.handleInputChange.bind(this)} required title="Enter agent details"/>
                        </div>
                      </div>
                      <div className="mt-3 mb-3">
                        <div className="text-right mb-3">
                          <button type="button" disabled={!this.state.agents_to_search} onClick={this.getAgents} className="btn btn-primary btn-lg m-1" title="Searches your agents">Search</button>
                          {/* <button type="button" name="agents_to_search" onClick={this.handleInputChange.bind(this)} className="btn btn-warning btn-lg m-1" title="Resets your search criteria">Reset</button> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.displayResults(results)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Agents;
