import React from "react";
import PropTypes from "prop-types";
import StyledTable from "@procter-gamble/pg-react-styledtable"; // used to display data in table
import { AgentColumns } from "../FieldDefinitions/Agents/SearchAgents";

class SearchAgentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var keyField = this.props.source === "agents" ? "id" : "something_else";
    var results = this.props.results;
    var columns = this.props.source === "agents" ? AgentColumns : AgentColumns;
    return (
      <div>
        <div className="clickable">
          <StyledTable
            keyField={keyField}
            results={results}
            columns={columns}
            defaultSort={"id"}
            showExport={true}
            excelColumns={columns}
            excelData={results}
          ></StyledTable>
        </div>
      </div>
    );
  }
}


SearchAgentsTable.defaultProps = {
  source: "agents",
  results: [],
};

SearchAgentsTable.propTypes = {
  source: PropTypes.string,
  results: PropTypes.array.isRequired,
};


export default SearchAgentsTable;