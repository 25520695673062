import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import PacmanLoader from "react-spinners/PacmanLoader";
// import Loader from 'react-loader-spinner';

export const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
      return (
        promiseInProgress &&
        <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <PacmanLoader
          size={75}
          color={"#003DA5"}
        />
          {/* <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" /> */}
        </div>
     );
    }