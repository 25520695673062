import React, { Component } from "react";
import Navigation from "@procter-gamble/pg-react-navigation";
import Header from "@procter-gamble/pg-react-header";
import Footer from "@procter-gamble/pg-react-footer";
import Home from "./Home/Home";
import Agents from "./Agents/SearchAgents";
import CrowdstrikeAgents from "./Agents/SearchCrowdstrikeAgents";
import searchlogsource from "./Agents/SearchLogSource";
import TenableAgents from "./Agents/SearchTenableAgents";
import { LoadingIndicator } from "./Helper/LoadingSpinner"
import { Alert } from './Helper/Alerts/_components/Alert';
import { history } from './Helper/Alerts/_helpers/history';
import { BrowserRouter as Router, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@procter-gamble/pg-styles";
import "@procter-gamble/pg-styles/css/fontawesome.min.css";

// HEADER
const title = "LMA Portal"; // displays title in header
const search = false; // displays searchbar in header
const iconFragment = (
  <React.Fragment>
  </React.Fragment>
);


// NAVIGATION BAR
const items = [
  {
    id: "home",
    title: "Home",
    link: "/",
    external: false,
    hideSideNav: true, //hides the side bar
  },
  {
    id: "search_crowdstrike_agents",
    title: "Search CrowdStrike Agents",
    link: "/search_crowdstrike_agents",
    external: false,
    hideSideNav: true, //hides the side bar
  },
  {
    id: "search_agents",
    title: "Search Wazuh Agents",
    link: "/search_agents",
    external: false,
    hideSideNav: true, //hides the side bar
  },
  {
    id: "search Nessus_agents",
    title: "Search Nessus Agents",
    link: "/search_nessus_agents",
    external: false,
    hideSideNav: true, //hides the side bar
  },
  {
    id: "search_log_source",
    title: "Search Log Source",
    link: "/search_log_source",
    external: false,
    hideSideNav: true, //hides the side bar
  }
];

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
   
  }

  render() {
    return (
      <Router history={history}>
        <>
          <Header title={title} search={search} iconFragment={iconFragment} />
          <Navigation items={items}>
            {/* Below adds links to top of page across all pages */}
            {/* <div className="container-fluid mb-auto">
              <Link to="/" className="btn btn-link">Single Alert</Link>
              <Link to="/multi-alerts" className="btn btn-link">Multiple Alerts</Link>
            </div> */}
            <div className="container-fluid mb-auto">
              <Alert />
              <Route exact path="/" component={Home} />
              <Route path="/search_agents" component={Agents} />
              <Route path="/search_crowdstrike_agents" component={CrowdstrikeAgents} />
              <Route path="/search_nessus_agents" component={TenableAgents} />
              <Route path="/search_log_source" component={searchlogsource} />
              <LoadingIndicator/>
            </div>
          </Navigation>
          <Footer />
        </>
      </Router>
    );
  }
}

export default Main;
