import GoogleAnalytics from "react-ga";
import { alertService } from './Alerts/_services/alert.service'; //https://github.com/cornflourblue/react-alert-notifications/blob/master/src/home/Home.jsx


// ALERTS


// Display alerts on screen
export function displayAlert(type, message) {
//eslint-disable-next-line
    {
      type === "success" ? alertService.success("Success: " + message, { autoClose: true, keepAfterRouteChange: false })
      : type === "warning" ? alertService.warn("Warning: " + message, { autoClose: true, keepAfterRouteChange: false })
        : type === "error" ? alertService.error("Error: " + message, { autoClose: true, keepAfterRouteChange: false })
          : type === "info" ? alertService.info(message, { autoClose: false, keepAfterRouteChange: false })
            : alertService.error("Error: Unknown alert type was passed.", { autoClose: true, keepAfterRouteChange: false })
    }
}


// General


// check if a field is valid
export function fieldExists(field, result) {
    return (field in result && result[field].length);
}

// creates an image of equal, static width and height
export function getImageStyle(widthHeight) {
    var imageDimensions = {
        width: "",
        height: "",
        maxWidth: "",
        maxHeight: "",
        minWidth: "",
        minHeight: ""
    };
    var imageStyle = {};
    Object.keys(imageDimensions).forEach(styleProperty => {
        imageStyle[styleProperty] = widthHeight;
    });
    return imageStyle;
}


// Search

// create a search string for api call (with input cleanup)
export function createSearch(beginning, fields, obj) {
    // Add each value/id pair to search string
    var searchString = beginning;
    fields.forEach(field => {
        let id = field.id;
        let value = obj[id];
        if (value !== undefined) {
            // remove < and > to protect against HTML injection
            value = value
                .toString()
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace("&", "%26");
            // if it is comma-delimited, split it up
            if (field.help === "true") {
                // replaces ", " with ","
                let splitValue = value.replace(", ", ",");
                searchString += "&" + id + "=" + splitValue;
                // TODO: check the string with regex for shortname
            } else {
                searchString += "&" + id + "=" + value;
            }
        }
    });
    return searchString;
}

// handles successful searches logic
export function successSearch(category, source, response, setData, callback) {
    var result = {
        data: [],
        showNoResults: false,
        showTooManyResults: false,
        showError: false
    };
    if (response === -1) {
        result.showTooManyResults = true;
        callGA(category, "Search", category, source, false, true);
        return callback(result);
    } else {
        setData(response, cb => {
            result.data = cb.data;
            result.showNoResults = cb.showNoResults;
            callGA(category, "Search", category, source, false, false);
            return callback(result);
        });
    }
}

// handle errored search
export function errorSearch(category, source, error, callback) {
    var result = {
        data: [],
        showNoResults: false,
        showTooManyResults: false,
        showError: true
    };
    handleError(error, category, "Search", category, source, cb => {
        callGA(category, "Search", category, source, true, false);
        return callback(result);
    });
}

// User


// check if a user is active
export function isActive(user) {
    if (fieldExists("extstatus", user) &&
        user["extstatus"][0] === "0"
    ) {
        // employee
        if (fieldExists("employeetype", user) &&
            typeof user["employeetype"] === "string"
        ) {
            if (fieldExists("employeetype", user) &&
                user["employeetype"] === "Employee"
            ) {
                // check extHRStatusID
                if (fieldExists("exthrstatusid", user) &&
                    (user["exthrstatusid"][0] === "1" || user["exthrstatusid"][0] === "3")
                ) {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            if (
                fieldExists("employeetype", user) &&
                user["employeetype"][0] === "Employee"
            ) {
                // check extHRStatusID
                if (
                    fieldExists("exthrstatusid", user) &&
                    (user["exthrstatusid"][0] === "1" || user["exthrstatusid"][0] === "3")
                ) {
                    return true;
                }
            } else {
                return true;
            }
        }
    }
    return false;
}

// Criteria

// handle criteria changes
export function criteriaUpdate(criteria, fields, callback) {
    let result = {};
    fields.forEach(field => {
        if (field.id in criteria) {
            field.text = criteria[field.id];
        } else {
            field.text = "";
        }
    });
    result.criteria = criteria;
    result.fields = fields;
    callback(result);
}

// handle criteria change when done through helper modals
export function multiUpdate(tempValues, criteria, fields, callback) {
    // Deep copy object....
    let obj = JSON.parse(JSON.stringify(criteria));

    Object.keys(tempValues).forEach(id => {
        var value = tempValues[id].value;
        var tempField = tempValues[id].field;
        // Check Item Type
        if (tempField.type === "TextEdit") {
            obj[tempField.id] = [value];
            fields.forEach(field => {
                if (field.id === tempField.id) {
                    if (tempField.help) {
                        tempField.text = value;
                    }
                }
            });
        } else {
            obj[tempField.id] = value.map(tempField => tempField.id);
        }

        // If user deletes an item, (Select = [], Or Input = [''], or Date start/end = null) delete from criteria
        if (
            obj[tempField.id].length === 0 ||
            (obj[tempField.id].length > 0 && obj[tempField.id][0] === "") ||
            (tempField.type === "DateEdit" &&
                (obj[tempField.id].startDate === null ||
                    obj[tempField.id].endDate === null))
        ) {
            delete obj[tempField.id];
        }
    });
    return callback(obj);
}


// Direct Links

// get the query parameters for direct links
export function getQueryPath(splitBy) {
    var path = window.location.pathname;
    // split by the given variable (ex. /people/)
    var pathList = path.split(splitBy);
    // if the split is successful, set the returned path variable
    if (pathList.length === 2) {
        return pathList[1];
    } else {
        return "";
    }
}


// Error Handling

// handling error based on cookie and setting appropriate google analytics for it
export function handleError(error, category, action, specifics, src, cb) {
    if (error.response.status === 403) {
        specifics = action + " " + specifics;
        callGA("Cookie Refresh", "Refresh", "Cookie", specifics, 0, 0);
        window.location.reload();
    } else {
        callGA(category, action, specifics, src, true, false);
        cb("error");
    }
}


// Google Analytics

// call Google Analytics with correct formatting
export function callGA(category, action, specifics, src, errored, tooMany) {
    let label = "";
    if (errored) {
        label += "Error: ";
    } else if (tooMany) {
        label += "Too Many: ";
    }
    GoogleAnalytics.event({
        category: category,
        action: action + " " + specifics,
        label: label + action + " " + specifics + " (" + src + ")"
    });
}